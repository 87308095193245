import classes from "./General.module.css";

const Support = () => {
  return (
    <div className={classes.card}>
      <h1>& Support</h1>
      <p>
        Giving back to the community we live and work in is an integral part of
        who we are. In addition to firm initiatives, we also support individual
        volunteering efforts.
      </p>
    </div>
  );
};

export default Support;
