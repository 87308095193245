import classes from "./Industries.module.css";

const Industries = () => {
  return (
    <section className={classes.industries}>
      <div className={classes.detail}>
        <h1>Aerospace</h1>
        <p>
          Advice on all aspects of aerospace, from daily operations to intricate
          finance and leasing arrangements.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Agribusiness, food and beverage</h1>
        <p>
          Agriculture is in our DNA. We work with businesses at all stages of
          the value chain.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Construction</h1>
        <p>
          Guidance for clients across the sector, including owners, contractors,
          engineers, architects, and suppliers.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Financial services</h1>
        <p>
          A leading strategic advisor to major chartered banks, other financial
          institutions, and leasing companies.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Franchises, dealerships and distributorships</h1>
        <p>
          Setup and branding, plus all legal aspects of dealership,
          distributorship, and franchise operations.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Government and not for profit</h1>
        <p>
          Specialized knowledge on new legislation, public policy, operational
          matters, compliance, and more.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Life sciences</h1>
        <p>
          Skilled, practical, forward-thinking advice and protection to keep up
          with this fast-moving industry.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Oil and gas</h1>
        <p>
          As respected leaders in this field, we advise on all areas of domestic
          and international energy matters.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Power</h1>
        <p>
          Legal and commercially sound solutions for all types and at all stages
          of power projects.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Real estate</h1>
        <p>
          Responsive solutions for builders, developers, landlords, tenants,
          lenders, borrowers, and corporations.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Renewables and energy transition</h1>
        <p>
          Successfully navigate this complex industry, whether it's a new or
          existing project, or a transition strategy.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Start-up and early-stage companies</h1>
        <p>
          Strategic legal advice, experience, and sound judgment to guide you
          through all stages of development.
        </p>
      </div>
      <div className={classes.detail}>
        <h1>Technology</h1>
        <p>
          Our skilled, knowledgeable team will help you start, scale, sell, and
          protect your intellectual property.
        </p>
      </div>
    </section>
  );
};

export default Industries;
