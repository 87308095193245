import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// const axiosInstance = axios.create({
//   baseURL: "https://api.orohandel.com/api/v1/",
// });

export const emailAdmin = async (data) => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "email/send",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
