import classes from "./General.module.css";

const Ambition = () => {
  return (
    <div className={classes.card}>
      <h1>& Ambition</h1>
      <p>
        We are a passionate team with different perspectives. But we all share a
        boundless curiosity, unwavering in our dedication to find solutions-and
        change the world.
      </p>
    </div>
  );
};

export default Ambition;
