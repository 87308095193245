import classes from "./General.module.css";

const Skills = () => {
  return (
    <div className={classes.card}>
      <h1>Use our skills</h1>
      <p>
        Indept knowledge of your industry isn't enough. We dig (way) deeeper to
        understand your risks, tolerance, needs and business objectives.
      </p>
    </div>
  );
};

export default Skills;
